var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{ref:"answerable",attrs:{"id":"bv-answerable","no-close-on-backdrop":"","size":"lg","hide-footer":""},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_vm._v(" Movimiento de Stock ")]},proxy:true}])},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Ubicaciones","label-for":"Ubicaciones"}},[_c('ValidationProvider',{attrs:{"name":"Ubicación","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:errors.length > 0 ? ' is-invalid' : '',attrs:{"label":"name","reduce":function (name) { return name.id; },"options":_vm.locationes,"placeholder":"Seleccionar...","selectable":function (item) { return (item.id === 1 ? item.disabled : item); }},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){return [_vm._v(" Sin Resultados,"),_c('b-button',{attrs:{"variant":"link","block":""},on:{"click":function($event){return _vm.$refs.create.showModal()}}},[_vm._v("Agregar ubicación")])]}},{key:"option",fn:function(option){return [_vm._t("default",[_vm._v(" "+_vm._s(option.name)+" "),(option.id === 1 ? true : false)?_c('button',{staticClass:"btn btn-link btn-block",on:{"click":function($event){return _vm.$refs.create.showModal()}}},[_vm._v(" Agregar ubicación ")]):_vm._e()])]}}],null,true),model:{value:(_vm.form.location),callback:function ($$v) {_vm.$set(_vm.form, "location", $$v)},expression:"form.location"}}),_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Área","label-for":"Área"}},[_c('ValidationProvider',{attrs:{"name":"Área","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:errors.length > 0 ? ' is-invalid' : '',attrs:{"label":"name","placeholder":"Seleccionar...","reduce":function (name) { return name.id; },"options":_vm.areas,"selectable":function (item) { return (item.id === 1 ? item.disabled : item); }},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){return [_vm._v(" Sin Resultados,"),_c('b-button',{attrs:{"variant":"link","block":""},on:{"click":function($event){return _vm.$refs.createOrUpdate.showModal()}}},[_vm._v("Agregar área")])]}},{key:"option",fn:function(option){return [_vm._t("default",[_vm._v(" "+_vm._s(option.name)+" "),(option.id === 1 ? true : false)?_c('button',{staticClass:"btn btn-link btn-block",on:{"click":function($event){return _vm.$refs.createOrUpdate.showModal()}}},[_vm._v(" Agregar área ")]):_vm._e()])]}}],null,true),model:{value:(_vm.form.area),callback:function ($$v) {_vm.$set(_vm.form, "area", $$v)},expression:"form.area"}}),_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"form-group"},[_c('b-form-group',{attrs:{"label":"Comentario","label-for":"Comentario"}},[_c('ValidationProvider',{attrs:{"name":"Comentario","rules":"required|min:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{class:errors.length > 0 ? ' is-invalid' : '',attrs:{"placeholder":"Ingresa una descripción referente al movimiento de stock","rows":"1","max-rows":"1"},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}}),_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1)],1)])],1),_c('hr'),_c('b-button',{staticClass:"mr-2",attrs:{"variant":"primary","type":"submit"}},[_vm._v("Guardar ")]),_c('b-button',{staticClass:"mr-2",attrs:{"variant":" iq-bg-danger mr-1"},on:{"click":function($event){return _vm.$bvModal.hide('bv-answerable')}}},[_vm._v("Cerrar")])],1)]}}])})],1),_c('modals',{ref:"createOrUpdate"}),_c('modals-ubication',{ref:"create"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }