<template>
  <div>
  <b-modal
    ref="answerable"
    id="bv-answerable"
    no-close-on-backdrop
    size="lg"
    hide-footer
  >
    <template #modal-title> Movimiento de Stock </template>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <b-row>
          <b-col md="6">
            <!-- SELECT UBICACIONES -->
            <b-form-group label="Ubicaciones" label-for="Ubicaciones">
              <ValidationProvider
                name="Ubicación"
                rules="required"
                v-slot="{ errors }"
              >
                <v-select
                  label="name"
                  :reduce="(name) => name.id"
                  v-model="form.location"
                  :options="locationes"
                  placeholder="Seleccionar..."
                  :class="errors.length > 0 ? ' is-invalid' : ''"
                   :selectable="
                        (item) => (item.id === 1 ? item.disabled : item)
                      "
                >
                  <template #no-options="{}">
                        Sin Resultados,<b-button
                          variant="link"
                          block
                          @click="$refs.create.showModal()"
                          >Agregar ubicación</b-button
                        >
                      </template>
                      <template v-slot:option="option">
                        <slot>
                          {{ option.name }}
                          <button
                            v-if="option.id === 1 ? true : false"
                            class="btn btn-link btn-block"
                            @click="$refs.create.showModal()"
                          >
                            Agregar ubicación
                          </button></slot
                        >
                      </template>
                </v-select>
                <div class="invalid-feedback">
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <!-- SELECT AREA -->
            <b-form-group label="Área" label-for="Área">
              <ValidationProvider
                name="Área"
                rules="required"
                v-slot="{ errors }"
              >
                <v-select
                  label="name"
                  v-model="form.area"
                  placeholder="Seleccionar..."
                  :reduce="(name) => name.id"
                  :options="areas"
                  :class="errors.length > 0 ? ' is-invalid' : ''"
                  :selectable="(item) => (item.id === 1 ? item.disabled : item)"
                >
                  <template #no-options="{}">
                    Sin Resultados,<b-button
                      variant="link"
                      block
                      @click="$refs.createOrUpdate.showModal()"
                      >Agregar área</b-button
                    >
                  </template>
                  <template v-slot:option="option">
                    <slot>
                      {{ option.name }}
                      <button
                        v-if="option.id === 1 ? true : false"
                        class="btn btn-link btn-block"
                        @click="$refs.createOrUpdate.showModal()"
                      >
                        Agregar área
                      </button></slot
                    >
                  </template>
                </v-select>
                <div class="invalid-feedback">
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <!-- cantidad -->
            <div class="form-group">
              <b-form-group label="Comentario" label-for="Comentario">
                <ValidationProvider
                  name="Comentario"
                  rules="required|min:10"
                  v-slot="{ errors }"
                >
                  <b-form-textarea
                    v-model="form.description"
                    placeholder="Ingresa una descripción referente al movimiento de stock"
                    rows="1"
                    max-rows="1"
                    :class="errors.length > 0 ? ' is-invalid' : ''"
                  ></b-form-textarea>
                  <div class="invalid-feedback">
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </b-form-group>
            </div>
          </b-col>
        </b-row>
        <hr />
        <b-button variant="primary" type="submit" class="mr-2"
          >Guardar
        </b-button>
        <b-button
          variant=" iq-bg-danger mr-1"
          class="mr-2"
          @click="$bvModal.hide('bv-answerable')"
          >Cerrar</b-button
        >
      </form>
    </ValidationObserver>
  </b-modal>
    <modals ref="createOrUpdate" />
    <modals-ubication ref="create" />

  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { core } from "@/config/pluginInit";
import modals from "@/components/core/modals/AreaModals.vue";
import modalsUbication from '@/components/core/modals/LocationModals'
export default {
  components: {
     modals,
     modalsUbication
  },
  async created() {
    // CARGAR LISTADO DE AREAS
       await this.fetchAreas();
    await this.listUbication();
  },
  data() {
    return {
      form: {
        product_responsable: "",
        location: "",
        area: "",
        description: "",
      },
    };
  },
  methods: {
     ...mapActions({
      fetchAreas: "area/listArea",
      listUbication: "ubication/listUbication",
    }),
    show(id) {
      this.form = {
        product_responsable: "",
        location: "",
        area: "",
        description: "",
      };
      this.form.product_responsable = id;
      this.$bvModal.show("bv-answerable");
    },
    async onSubmit() {
      try {
        await this.$store.dispatch(
          "products/movementStockAnswerable",
          this.form
        );
        this.form = {
          product_responsable: "",
          location: "",
          area: "",
          description: "",
        };
        core.showSnackbar("success", "Solicitud Realizada");
        this.$bvModal.hide("bv-answerable");
      } catch (error) {
        console.log(error);
        const errors = error.response.data.errors;
        for (const key in errors) {
          core.showSnackbar("error", errors[key][0]);
          return false;
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      getAreaList: "area/getAreaList",
      getUbication: "ubication/getUbication",
    }),
    areas(){
      return this.getAreaList
    },
    locationes(){
      return this.getUbication
    }

  }
};
</script>
