var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('iq-card',{scopedSlots:_vm._u([{key:"headerTitle",fn:function(){return [_c('h4',{staticClass:"card-title"},[_vm._v("Responsables")])]},proxy:true},{key:"headerAction",fn:function(){return [(!_vm.down)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":"Solicitud de baja","variant":" iq-bg-danger mr-1 mb-1","size":"sm"},on:{"click":function($event){return _vm.$bvModal.show('modal-categories-delete')}}},[_c('i',{staticClass:"d-block d-sm-none ri-file-shred-line ml-1"}),_c('span',{staticClass:"d-none d-sm-block d-md-block"},[_vm._v(" Solicitud de baja múltiple de productos")])]):_vm._e(),(_vm.down)?_c('b-button',{attrs:{"variant":" iq-bg-success mr-1 mb-1","size":"sm"},on:{"click":function($event){return _vm.remove()}}},[_c('i',{staticClass:"ri-file-shred-line ml-1"}),_vm._v(" Continuar ")]):_vm._e(),(_vm.down)?_c('b-button',{attrs:{"variant":" iq-bg-secondary mr-1 mb-1","size":"sm"},on:{"click":function($event){_vm.down = false}}},[_c('i',{staticClass:"ri-close-circle-line ml-1"}),_vm._v(" Cancelar ")]):_vm._e()]},proxy:true},{key:"body",fn:function(){return [(_vm.down)?_c('p',[_vm._v(" Selecciones el o los productos, los cuales desea que esten en la solicitud de baja de productos y presione el boton de continuar ")]):_vm._e(),_c('b-row',[_c('b-col',{attrs:{"md":"2","sm":"12"}},[_c('b-form-group',{staticClass:"mb-4"},[_c('b-input-group',[_c('b-form-select',{attrs:{"options":[5, 10, 20, 100],"size":"sm"},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}})],1)],1)],1),_c('b-col',{staticClass:"ml-auto",attrs:{"md":"3","sm":"12"}},[_c('b-form-group',{staticClass:"mb-4"},[_c('b-input-group',[_c('b-form-input',{attrs:{"id":"filter-input","disabled":!Array.isArray(_vm.getAnswerable) ||
                      (_vm.getAnswerable.length === 0 && _vm.isBusy === false),"type":"search","placeholder":"Buscar..."},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})],1)],1)],1),_c('b-col',{staticClass:"table-responsive",attrs:{"md":"12"}},[_c('b-table',{attrs:{"busy":!Array.isArray(_vm.getAnswerable) || _vm.getAnswerable.length === 0
                    ? true
                    : false || _vm.isBusy == true,"items":_vm.getAnswerable,"fields":_vm.columns,"filter":_vm.filter,"per-page":_vm.perPage,"current-page":_vm.currentPage},on:{"filtered":_vm.onFiltered},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-danger my-2"},[(_vm.isBusy)?_c('div',[_c('b-spinner',{staticClass:"align-middle",attrs:{"type":"grow"}}),_c('strong',[_vm._v(" Cargando datos...")])],1):_c('span',{staticClass:"h5"},[_vm._v("Sin registros")])])]},proxy:true},{key:"cell(product)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.product.name)+" ")]}},{key:"cell(answerable)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.responsable.name)+" ")]}},(_vm.down)?{key:"cell(select)",fn:function(data){return [_c('b-form-checkbox-group',{model:{value:(_vm.form.product_responsables),callback:function ($$v) {_vm.$set(_vm.form, "product_responsables", $$v)},expression:"form.product_responsables"}},[_c('b-form-checkbox',{attrs:{"disabled":data.item.status === 4
                          ? true
                          : data.item.status === 2
                          ? true
                          : false,"value":data.item.id}})],1)]}}:null,{key:"cell(area)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.area.name)+" ")]}},{key:"cell(location)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.location.name)+" ")]}},{key:"cell(status)",fn:function(data){return [_c('b-badge',{attrs:{"variant":data.item.status === 1
                        ? 'success'
                        : data.item.status === 2
                        ? 'primary'
                        : data.item.status === 3
                        ? 'dark'
                        : 'danger'}},[_vm._v(_vm._s(data.item.status_text))])]}},{key:"cell(action)",fn:function(data){return [_c('div',{staticClass:"row ml-2"},[_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(data.item.status !== 2),expression:"data.item.status !== 2"},{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":"Movimiento de stock","disabled":data.item.status !== 4 ? false : true,"variant":" iq-bg-primary mr-1 mb-1 col-5","size":"sm"},on:{"click":function($event){return _vm.$refs.changeAnswerable.show(data.item.id)}}},[_c('i',{staticClass:"ri-share-forward-box-fill ml-1"})]),_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(data.item.status !== 2),expression:"data.item.status !== 2"},{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":"Cambiar responsable","variant":" iq-bg-warning mr-1 mb-1 col-5","disabled":data.item.status !== 4 ? false : true,"size":"sm"},on:{"click":function($event){return _vm.show(data.item)}}},[_c('i',{staticClass:"ri-ball-pen-fill ml-1"})])],1)]}}],null,true)}),(_vm.totalRows > _vm.perPage)?_c('b-pagination',{staticClass:"mt-3",attrs:{"total-rows":_vm.totalRows,"per-page":_vm.perPage,"first-number":"","pills":"","size":"sm","align":"center"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e()],1)],1)]},proxy:true}])}),_c('AnswerableEgreso',{ref:"AnswerableEgreso",attrs:{"state":_vm.isBusy},on:{"refresh":_vm.refresh}}),_c('iq-card',{scopedSlots:_vm._u([{key:"headerTitle",fn:function(){return [_c('h4',{staticClass:"card-title"},[_vm._v("Productos retirados")])]},proxy:true},{key:"body",fn:function(){return [_c('b-table',{attrs:{"hover":"","fields":_vm.columns.filter(function (item) { return item.key !== 'action'; }),"items":_vm.getRecalled,"per-page":_vm.perPage,"current-page":_vm.currentPage,"busy":!Array.isArray(_vm.getRecalled) || _vm.getRecalled.length === 0
                ? true
                : false || _vm.isBusy == true},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-danger my-2"},[(_vm.isBusy)?_c('div',[_c('b-spinner',{staticClass:"align-middle",attrs:{"type":"grow"}}),_c('strong',[_vm._v(" Cargando datos...")])],1):_c('span',{staticClass:"h5"},[_vm._v("Sin registros")])])]},proxy:true},{key:"cell(area)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.area.name)+" ")]}},{key:"cell(answerable)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.responsable.name)+" ")]}},{key:"cell(location)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.location.name)+" ")]}},{key:"cell(product)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.product.name)+" ")]}},{key:"cell(status)",fn:function(data){return [_c('b-badge',{attrs:{"variant":data.item.status === 1
                    ? 'success'
                    : data.item.status === 2
                    ? 'primary'
                    : data.item.status === 3
                    ? 'dark'
                    : 'danger'}},[_vm._v(_vm._s(data.item.status_text))])]}}])}),(_vm.getRecalled.length > _vm.perPage)?_c('b-pagination',{staticClass:"mt-3",attrs:{"total-rows":_vm.getRecalled.length,"per-page":_vm.perPage,"first-number":"","pills":"","size":"sm","align":"center"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e()]},proxy:true}])})],1)],1),_c('div',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var handleSubmit = ref.handleSubmit;
return [_c('b-modal',{ref:"my-remove",attrs:{"no-close-on-backdrop":"","id":"remove","hide-footer":""},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_vm._v(" Solicitud De Baja De Producto")]},proxy:true}],null,true)},[_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('b-form-group',{staticClass:"col-md-12",attrs:{"label-for":"Documento","label":"Documento:"}},[_c('b-form-file',{staticClass:"p-2",attrs:{"accept":"pdf","placeholder":"Soporte de Solicitud","drop-placeholder":"Drop file here...","browse-text":"Cargar"},on:{"input":function($event){return _vm.validatePDF()}},model:{value:(_vm.form.file_request),callback:function ($$v) {_vm.$set(_vm.form, "file_request", $$v)},expression:"form.file_request"}})],1),_c('b-form-group',{staticClass:"col-md-12",attrs:{"label-for":"Descripción","label":"Descripción:"}},[_c('ValidationProvider',{attrs:{"name":"Descripción","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-textarea',{class:errors.length > 0 ? ' is-invalid' : '',attrs:{"placeholder":"Motivo para dar de baja a producto","rows":"1","max-rows":"6"},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}}),_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('hr'),_c('b-button',{staticClass:"mt-3 ml-1 float-right",attrs:{"variant":"primary","type":"submit"}},[_vm._v("Guardar")]),_c('b-button',{staticClass:"mt-3 float-right iq-bg-danger",attrs:{"variant":"none"},on:{"click":function($event){return _vm.cancel()}}},[_vm._v("Cerrar")])],1)]),_c('b-modal',{attrs:{"id":"bv-change","no-close-on-backdrop":"","hide-footer":""},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_vm._v(" Cambiar Responsable")]},proxy:true}],null,true)},[_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('ValidationProvider',{attrs:{"name":"Responsable","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-select',{class:errors.length > 0 ? ' is-invalid' : '',attrs:{"label":"fullname","placeholder":"Seleccionar...","options":_vm.getUsers},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){return [_vm._v(" Sin Resultados... ")]}}],null,true),model:{value:(_vm.user),callback:function ($$v) {_vm.user=$$v},expression:"user"}}),_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('hr'),_c('b-button',{attrs:{"variant":"primary","block":"","type":"submit"}},[_vm._v("Guardar")])],1)])]}}])})],1),_c('changeAndwerable',{ref:"changeAnswerable",on:{"update":_vm.update}}),_c('b-modal',{attrs:{"no-close-on-backdrop":"","title":"ATENCIÓN","id":"modal-categories-delete","ok-title":"SI, Deseo continuar","cancel-title":"NO, Cancelar"},on:{"ok":function($event){_vm.down = true},"cancel":function($event){_vm.down = false}}},[_c('p',{staticClass:"my-4"},[_vm._v("¿Desea realizar una solicitud de baja de productos?")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }