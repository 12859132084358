<template>
  <div>
    <b-modal id="bv-modal-area" hide-footer>
      <template #modal-title>
        {{ form.id === null ? "Agregar nueva área" : "Editar área" }}
      </template>
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(onSubmit)">
          <b-row>
            <b-form-group class="col-md-12" label="Nombre:" label-for="Nombre">
              <ValidationProvider
                name="Nombre"
                rules="required"
                v-slot="{ errors }"
              >
                <b-form-input
                  v-model="form.name"
                  type="text"
                  placeholder="Nombre de Área"
                  :class="errors.length > 0 ? ' is-invalid' : ''"
                >
                </b-form-input>
                <div class="invalid-feedback">
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </b-form-group>
          </b-row>
          <hr />
          <b-button variant="primary" block type="submit">Guardar</b-button>
        </form>
      </ValidationObserver>
    </b-modal>
    <div></div>
  </div>
</template>
 <script>
import { mapActions } from "vuex";
import { core } from "@/config/pluginInit";
export default {
  mounted() {
    core.index();
  },
  data() {
    return {
      form: {
        id: null,
        name: null,
      },
    };
  },
  methods: {
    ...mapActions({
      storeArea: "area/storeArea",
    }),
    showModal(item) {
      if (item) {
        this.form = {
          id: item.id,
          name: item.name,
        };
      } else {
        this.form = {
          id: null,
          name: null,
        };
      }
      this.$bvModal.show("bv-modal-area");
    },
    async onSubmit() {
      try {
        const res = await this.storeArea(this.form);
        core.showSnackbar("success", res);
        this.$bvModal.hide("bv-modal-area");
        this.form = {
          id: null,
          name: null,
        };
        this.$emit('refresh')
        //this.totalRows = this.getAreaList.length;
      } catch (error) {
        console.log(error);
        const errors = error.response.data.errors;
        for (const key in errors) {
          core.showSnackbar("error", errors[key][0]);
          return false;
        }
      }
    },
  },
};
</script>