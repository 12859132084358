<template>
  <b-modal id="historial" size="lg" scrollable hide-footer :title="title">
    <b-tabs card pills>
      <b-tab no-body title="Pendientes">
        <b-table
          responsive
          :fields="columns"
          :items="pending"
          :busy="
            !Array.isArray(pending) || pending.length === 0
              ? true
              : false || isBusy == true
          "
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <div v-if="isBusy">
                <b-spinner type="grow" class="align-middle"></b-spinner>
                <strong> Cargando datos...</strong>
              </div>
              <span class="h5" v-else>Sin registros</span>
            </div>
          </template>
          <template v-slot:cell(type)="data">
             <strong>{{type}}</strong>
          </template>
          <template v-slot:cell(status_text)="data">
            <b-badge pill class="bg-info">
              <h5 class="text-white">
                {{ data.item.status_text }}
              </h5>
            </b-badge>
          </template>
        </b-table>
      </b-tab>

      <b-tab no-body title="Aprobadas">
        <b-table
          responsive
          :fields="columns"
          :items="passed"
          :busy="
            !Array.isArray(passed) || passed.length === 0
              ? true
              : false || isBusy == true
          "
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <div v-if="isBusy">
                <b-spinner type="grow" class="align-middle"></b-spinner>
                <strong> Cargando datos...</strong>
              </div>
              <span class="h5" v-else>Sin registros</span>
            </div>
          </template>
          <template v-slot:cell(type)="data">
            <strong>{{type}}</strong>
          </template>
          <template v-slot:cell(status_text)="data">
            <b-badge pill class="bg-success">
              <h5 class="text-white">
                {{ data.item.status_text }}
              </h5>
            </b-badge>
          </template>
        </b-table>
      </b-tab>

      <b-tab no-body title="Rechazadas">
        <b-table
          responsive
          :fields="columns"
          :items="rejected"
          :busy="
            !Array.isArray(rejected) || rejected.length === 0
              ? true
              : false || isBusy == true
          "
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <div v-if="isBusy">
                <b-spinner type="grow" class="align-middle"></b-spinner>
                <strong> Cargando datos...</strong>
              </div>
              <span class="h5" v-else>Sin registros</span>
            </div>
          </template>
          <template v-slot:cell(type)="data">
            <strong>{{type}}</strong>
          </template>
          <template v-slot:cell(status_text)="data">
            <b-badge pill class="bg-danger">
              <h5 class="text-white">
                {{ data.item.status_text }}
              </h5>
            </b-badge>
          </template>
        </b-table>
      </b-tab>
    </b-tabs>
  </b-modal>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: {
    pe: null,
    state: { default: false },
  },
  name: "Comentaries",
  data() {
    return {
      title: "Historial de solicitudes",
      pending: [],
      passed: [],
      rejected: [],
      isBusy: false,
      columns: [
        {
          label: "CÓDIGO",
          key: "code_product_responsable",
          class: "text-center",
        },
        { label: "PRODUCTO", key: "product", class: "text-center" },
        { label: "SOLICITANTE", key: "user.name", class: "text-center" },
        { label: "TIPO", key: "type", class: "text-center" },
        { label: "ESTADO", key: "status_text", class: "text-center" },
      ],
      type: ""
    };
  },
  methods: {
    show(type) {
       this.pending = [];
        this.passed = [];
        this.rejected = [];
        this.type = ""
      if (type === 1) {
        this.pending = this.egressPending;
        this.passed = this.egressPassed;
        this.rejected = this.egressRejected;
        this.type = "Egreso"
      } else {
        this.pending = this.movemenPending;
        this.passed = this.movemenPassed;
        this.rejected = this.movemenRejected;
        this.type = "Movimiento"
      }
      this.$bvModal.show("historial");
    },
  },
  computed: {
    ...mapGetters({
      movemenPending: "products/getMovemenPending",
      movemenPassed: "products/getMovemenPassed",
      movemenRejected: "products/getMovemenRejected",
      egressPending: "withdrawal/getwithdrawalPending",
      egressPassed: "withdrawal/getwithdrawalPassed",
      egressRejected: "withdrawal/getithdrawalRejected",
    }),
  },
};
</script>
