<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">Responsables</h4>
          </template>
          <template v-slot:headerAction>
            <b-button
              v-if="!down"
              v-b-tooltip.hover
              title="Solicitud de baja"
              variant=" iq-bg-danger mr-1 mb-1"
              size="sm"
              @click="$bvModal.show('modal-categories-delete')"
            >
              <i class="d-block d-sm-none ri-file-shred-line ml-1"></i>
              <span class="d-none d-sm-block d-md-block">
                Solicitud de baja múltiple de productos</span
              >
            </b-button>
            <b-button
              v-if="down"
              variant=" iq-bg-success mr-1 mb-1"
              size="sm"
              @click="remove()"
              ><i class="ri-file-shred-line ml-1"></i> Continuar
            </b-button>
            <b-button
              v-if="down"
              variant=" iq-bg-secondary mr-1 mb-1"
              size="sm"
              @click="down = false"
              ><i class="ri-close-circle-line ml-1"></i> Cancelar
            </b-button>
          </template>
          <template v-slot:body>
            <p v-if="down">
              Selecciones el o los productos, los cuales desea que esten en la
              solicitud de baja de productos y presione el boton de continuar
            </p>
            <b-row>
              <b-col md="2" sm="12">
                <b-form-group class="mb-4">
                  <b-input-group>
                    <b-form-select
                      v-model="perPage"
                      :options="[5, 10, 20, 100]"
                      size="sm"
                    ></b-form-select>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col md="3" sm="12" class="ml-auto">
                <b-form-group class="mb-4">
                  <b-input-group>
                    <b-form-input
                      id="filter-input"
                      :disabled="
                        !Array.isArray(getAnswerable) ||
                        (getAnswerable.length === 0 && isBusy === false)
                      "
                      v-model="filter"
                      type="search"
                      placeholder="Buscar..."
                    ></b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col md="12" class="table-responsive">
                <b-table
                  :busy="
                    !Array.isArray(getAnswerable) || getAnswerable.length === 0
                      ? true
                      : false || isBusy == true
                  "
                  :items="getAnswerable"
                  :fields="columns"
                  :filter="filter"
                  :per-page="perPage"
                  :current-page="currentPage"
                  @filtered="onFiltered"
                >
                  <template #table-busy>
                    <div class="text-center text-danger my-2">
                      <div v-if="isBusy">
                        <b-spinner type="grow" class="align-middle"></b-spinner>
                        <strong> Cargando datos...</strong>
                      </div>
                      <span class="h5" v-else>Sin registros</span>
                    </div>
                  </template>
                  <template v-slot:cell(product)="data">
                    {{ data.item.product.name }}
                  </template>
                  <template v-slot:cell(answerable)="data">
                    {{ data.item.responsable.name }}
                  </template>
                  <template v-slot:cell(select)="data" v-if="down">
                    <b-form-checkbox-group v-model="form.product_responsables">
                      <b-form-checkbox
                        :disabled="
                          data.item.status === 4
                            ? true
                            : data.item.status === 2
                            ? true
                            : false
                        "
                        :value="data.item.id"
                      ></b-form-checkbox>
                    </b-form-checkbox-group>
                  </template>
                  <template v-slot:cell(area)="data">
                    {{ data.item.area.name }}
                  </template>
                  <template v-slot:cell(location)="data">
                    {{ data.item.location.name }}
                  </template>
                  <template v-slot:cell(status)="data">
                    <b-badge
                      :variant="
                        data.item.status === 1
                          ? 'success'
                          : data.item.status === 2
                          ? 'primary'
                          : data.item.status === 3
                          ? 'dark'
                          : 'danger'
                      "
                      >{{ data.item.status_text }}</b-badge
                    >
                  </template>
                  <template v-slot:cell(action)="data">
                    <div class="row ml-2">
                      <b-button
                        v-show="data.item.status !== 2"
                        v-b-tooltip.hover
                        title="Movimiento de stock"
                        :disabled="data.item.status !== 4 ? false : true"
                        variant=" iq-bg-primary mr-1 mb-1 col-5"
                        size="sm"
                        @click="$refs.changeAnswerable.show(data.item.id)"
                      >
                        <i class="ri-share-forward-box-fill ml-1"></i
                      ></b-button>
                      <b-button
                        v-show="data.item.status !== 2"
                        v-b-tooltip.hover
                        title="Cambiar responsable"
                        variant=" iq-bg-warning mr-1 mb-1 col-5"
                        :disabled="data.item.status !== 4 ? false : true"
                        size="sm"
                        @click="show(data.item)"
                        ><i class="ri-ball-pen-fill ml-1"></i
                      ></b-button>
                      <!-- <b-button
                        v-b-tooltip.hover
                        title="Solicitud de baja"
                        variant=" iq-bg-danger mr-1 mb-1"
                        size="sm"
                        @click="remove(data.item)"
                        ><i class="ri-file-shred-line ml-1"></i>
                      </b-button> -->
                    </div>
                  </template>
                </b-table>
                <b-pagination
                  v-if="totalRows > perPage"
                  class="mt-3"
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  first-number
                  pills
                  size="sm"
                  align="center"
                ></b-pagination>
              </b-col>
            </b-row>
          </template>
        </iq-card>
        <AnswerableEgreso
          ref="AnswerableEgreso"
          v-on:refresh="refresh"
          :state="isBusy"
        />
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">Productos retirados</h4>
          </template>
          <template v-slot:body>
            <b-table
              hover
              :fields="columns.filter(item => item.key !== 'action')"
              :items="getRecalled"
              :per-page="perPage"
              :current-page="currentPage"
              :busy="
                !Array.isArray(getRecalled) || getRecalled.length === 0
                  ? true
                  : false || isBusy == true
              "
            >
              <template #table-busy>
                <div class="text-center text-danger my-2">
                  <div v-if="isBusy">
                    <b-spinner type="grow" class="align-middle"></b-spinner>
                    <strong> Cargando datos...</strong>
                  </div>
                  <span class="h5" v-else>Sin registros</span>
                </div>
              </template>
              <template v-slot:cell(area)="data">
                {{ data.item.area.name }}
              </template>
              <template v-slot:cell(answerable)="data">
                {{ data.item.responsable.name }}
              </template>
              <template v-slot:cell(location)="data">
                {{ data.item.location.name }}
              </template>
              <template v-slot:cell(product)="data">
                {{ data.item.product.name }}
              </template>
              <template v-slot:cell(status)="data">
                <b-badge
                  :variant="
                    data.item.status === 1
                      ? 'success'
                      : data.item.status === 2
                      ? 'primary'
                      : data.item.status === 3
                      ? 'dark'
                      : 'danger'
                  "
                  >{{ data.item.status_text }}</b-badge
                >
              </template>
            </b-table>
            <b-pagination
              v-if="getRecalled.length > perPage"
              class="mt-3"
              v-model="currentPage"
              :total-rows="getRecalled.length"
              :per-page="perPage"
              first-number
              pills
              size="sm"
              align="center"
            ></b-pagination>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <div>
      <ValidationObserver v-slot="{ handleSubmit }">
        <b-modal ref="my-remove" no-close-on-backdrop id="remove" hide-footer>
          <template #modal-title> Solicitud De Baja De Producto</template>
          <form @submit.prevent="handleSubmit(onSubmit)">
            <!-- PDF DE BAJA DE PRODUCTO -->
            <b-form-group
              class="col-md-12"
              label-for="Documento"
              label="Documento:"
            >
              <b-form-file
                class="p-2"
                v-model="form.file_request"
                accept="pdf"
                placeholder="Soporte de Solicitud"
                drop-placeholder="Drop file here..."
                browse-text="Cargar"
                @input="validatePDF()"
              ></b-form-file>
            </b-form-group>
            <!-- DESCRIPCION DE BAJA DE PRODUCTO -->
            <b-form-group
              class="col-md-12"
              label-for="Descripción"
              label="Descripción:"
            >
              <ValidationProvider
                name="Descripción"
                rules="required"
                v-slot="{ errors }"
              >
                <b-form-textarea
                  placeholder="Motivo para dar de baja a producto"
                  v-model="form.description"
                  rows="1"
                  max-rows="6"
                  :class="errors.length > 0 ? ' is-invalid' : ''"
                ></b-form-textarea>
                <div class="invalid-feedback">
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </b-form-group>
            <hr />
            <b-button
              class="mt-3 ml-1 float-right"
              variant="primary"
              type="submit"
              >Guardar</b-button
            >
            <b-button
              class="mt-3 float-right iq-bg-danger"
              variant="none"
              @click="cancel()"
              >Cerrar</b-button
            >
          </form>
        </b-modal>

        <b-modal id="bv-change" no-close-on-backdrop hide-footer>
          <template #modal-title> Cambiar Responsable</template>
          <form @submit.prevent="handleSubmit(submit)">
            <ValidationProvider
              name="Responsable"
              rules="required"
              v-slot="{ errors }"
            >
              <v-select
                label="fullname"
                v-model="user"
                placeholder="Seleccionar..."
                :options="getUsers"
                :class="errors.length > 0 ? ' is-invalid' : ''"
              >
                <template #no-options="{}"> Sin Resultados... </template>
              </v-select>
              <div class="invalid-feedback">
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
            <hr />
            <b-button variant="primary" block type="submit">Guardar</b-button>
          </form>
        </b-modal>
      </ValidationObserver>
    </div>
    <changeAndwerable ref="changeAnswerable" v-on:update="update" />
    <b-modal
      no-close-on-backdrop
      title="ATENCIÓN"
      id="modal-categories-delete"
      @ok="down = true"
      @cancel="down = false"
      ok-title="SI, Deseo continuar"
      cancel-title="NO, Cancelar"
    >
      <p class="my-4">¿Desea realizar una solicitud de baja de productos?</p>
    </b-modal>
  </b-container>
</template>
<script>
import { core } from "@/config/pluginInit";
import AnswerableEgreso from "@/components/core/table/AnswerableEgreso.vue";
import changeAndwerable from "@/components/core/modals/changeAndwerable.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "ListAnswerable",
  components: {
    AnswerableEgreso,
    changeAndwerable,
  },
  async created() {
    this.$store.commit("SET_BREADCRUMB", this.items);
    core.index();
    this.isBusy = true;
    // CARGAR INFORMACION DE TABLA
    await this.listAnswerable();
    await this.fetchUsers();
    await this.listArea();
    await this.listUbication();
    this.isBusy = false;
    this.totalRows = this.getAnswerable.length;
  },
  data() {
    return {
      down: false,
      form: {
        product_responsables: [],
        description: "",
        file_request: null,
      },
      id: "",
      user: "",
      answerable: "",
      isBusy: false,
      pdf: false,
      columns: [
        { label: "", key: "select", class: "text-center" },
        { label: "CÓDIGO", key: "code", class: "text-center" },
        { label: "PRODUCTO", key: "product", class: "text-center" },
        { label: "RESPONSABLE", key: "answerable", class: "text-center" },
        { label: "ÁREA", key: "area", class: "text-center" },
        { label: "UBICACIÓN", key: "location", class: "text-center" },
        { label: "ESTADO", key: "status", class: "text-center" },
        { label: "ACCIONES", key: "action", class: "text-center" },
      ],
      rows: [],
      perPage: 5,
      currentPage: 1,
      totalRows: 1,
      items: [
        {
          html: "Inicio",
          to: "/",
        },
        {
          text: "Responsable",
          active: true,
        },
      ],
      filter: null,
    };
  },
  methods: {
    ...mapActions({
      listArea: "area/listArea",
      listUbication: "ubication/listUbication",
      fetchUsers: "tool/fetchUsers",
      listAnswerable: "products/listAnswerable",
      updateAnswerable: "products/updateAnswerable",
      listWithdrawal: "withdrawal/listWithdrawal",
    }),
    async update() {
      this.isBusy = true;
      await this.listAnswerable();
      this.$refs.AnswerableEgreso.load();
      this.isBusy = false;
    },
    async refresh() {
      this.isBusy = true;
      await this.listAnswerable();
      this.isBusy = false;
    },
    cancel() {
      this.form = {
        product_responsables: [],
        file_request: null,
        description: "",
      };
      this.down = false;
      this.$bvModal.hide("remove");
    },

    validatePDF() {
      if (this.form.file_request) {
        if (this.form.file_request.type !== "application/pdf") {
          this.pdf = true;
        } else {
          this.pdf = false;
        }
      }
    },
    show(item) {
      this.id = item.id;
      this.user = { id: item.responsable.id, fullname: item.responsable.name };
      this.$bvModal.show("bv-change");
    },
    remove() {
      this.$bvModal.show("remove");
    },
    async onSubmit() {
      let payload = {
        product_responsables: this.form.product_responsables,
        file_request: this.form.file_request,
        description: this.form.description,
      };
      try {
        await this.$store.dispatch("withdrawal/storeWithdrawal", payload);
        await this.listAnswerable();
        await this.listWithdrawal();
        core.showSnackbar("success", "Solicitud Realizada");
        this.down = false;
        this.$bvModal.hide("remove");
        this.form = {
          product_responsables: [],
          file_request: null,
          description: "",
        };
      } catch (error) {
        /* this.form = {
          product_responsables: [],
          file_request: null,
          description: "",
        }; */
        this.down = false;
        const errors = error.response.data.errors;
        for (const key in errors) {
          core.showSnackbar("error", errors[key][0]);
          return false;
        }
      }
    },
    async submit() {
      try {
        await this.updateAnswerable({ id: this.id, user: this.user });
        this.$bvModal.hide("bv-change");
        core.showSnackbar("success", "Registro Editado");
      } catch (error) {
        this.$bvModal.hide("bv-change");
        console.log(error);
        let errors = error.response.data.errors;
        for (let key in errors) {
          core.showSnackbar("error", errors[key][0]);
          return false;
        }
      }
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
  computed: {
    ...mapGetters({
      getUsers: "tool/getUsers",
      getAnswerable: "products/getAnswerable",
      getRecalled: "products/getRecalled",
    }),
  },
};
</script>
<style>
.tooltip {
  opacity: 1 !important;
}
</style>
