<template>
  <div>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">Solicitudes de movimiento</h4>
      </template>
      <template v-slot:headerAction>
        <b-button
          variant="primary"
          @click="$refs.historial.show(0)"
          class="mr-2"
        >
          Ver Todas
        </b-button>
      </template>
      <template v-slot:body>
        <b-table
         hover
          :fields="columns"
          :items="rows"
          :per-page="perPage"
          :current-page="currentPage"
          :busy="
            !Array.isArray(rows) || rows.length === 0
              ? true
              : false || isBusy == true
          "
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <div v-if="isBusy">
                <b-spinner type="grow" class="align-middle"></b-spinner>
                <strong> Cargando datos...</strong>
              </div>
              <span class="h5" v-else>Sin registros</span>
            </div>
          </template>
          <template v-slot:cell(type)="data">
            <strong>Movimiento</strong>
          </template>
          <template v-slot:cell(status_text)="data">
            <b-badge pill class="bg-info">
              <h5 class="text-white">
                {{ data.item.status_text }}
              </h5>
            </b-badge>
          </template>
          <template v-slot:cell(action)="data">
            <!-- DETALLES DE SOLICITUD DE MOVIMIENTO -->
            <b-button
              v-b-tooltip.hover
              title="Ver detalles"
              class="iq-bg-primary mt-0 mr-2"
              variant="none"
              @click="show(data.item)"
              ><i class="ri-eye-line ml-1"></i>
            </b-button>
            <b-button
              v-b-tooltip.hover
              title="Aprobar Solicitud"
              v-can="'product_movement_requests.update'"
              class="iq-bg-success mt-0"
              variant="none"
              v-confirm="{
                ok: (dialog) => movement(data.item.id, 2),
                message: '¿Esta seguro de realizar este movimiento?',
              }"
              ><i class="ri-check-line ml-1"></i>
            </b-button>
            <b-button
              v-b-tooltip.hover
              title="Rechazar solicitud"
              v-can="'product_movement_requests.update'"
              class="iq-bg-danger mt-0 ml-2"
              variant="none"
              v-confirm="{
                ok: (dialog) => movement(data.item.id, 0),
                message: '¿Desea Cancelar Esta Solicitud?',
              }"
              ><i class="ri-close-circle-line ml-1"></i>
            </b-button>
          </template>
        </b-table>
        <b-pagination
          v-if="rows.length > perPage"
          class="mt-3"
          v-model="currentPage"
          :total-rows="rows.length"
          :per-page="perPage"
          first-number
          pills
          size="sm"
          align="center"
        ></b-pagination>
      </template>
    </iq-card>

    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">Solicitudes de Egreso de productos</h4>
      </template>
      <template v-slot:headerAction>
        <b-button
          variant="primary"
          @click="$refs.historial.show(1)"
          class="mr-2"
        >
          Ver Todas
        </b-button>
      </template>
      <template v-slot:body>
        
        <b-table
            hover
          :fields="columns"
          :items="egress"
          :per-page="perPage"
          :current-page="currentPage"
          :busy="
            !Array.isArray(egress) || egress.length === 0
              ? true
              : false || isBusy == true
          "
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <div v-if="isBusy">
                <b-spinner type="grow" class="align-middle"></b-spinner>
                <strong> Cargando datos...</strong>
              </div>
              <span class="h5" v-else>Sin registros</span>
            </div>
          </template>
          <template v-slot:cell(type)>
            <strong>Egreso</strong>
          </template>
          <template v-slot:cell(status_text)="data">
            <b-badge pill class="bg-info">
              <h5 class="text-white">
                {{ data.item.status_text }}
              </h5>
            </b-badge>
          </template>
          <template v-slot:cell(action)="data">
            <!-- DETALLES DE SOLICITUD DE MOVIMIENTO -->
            <b-button
              v-b-tooltip.hover
              title="Ver detalles"
              class="iq-bg-primary mt-0 mr-2"
              variant="none"
              @click="show(data.item)"
              ><i class="ri-eye-line ml-1"></i>
            </b-button>

            <b-button
              v-b-tooltip.hover
              title="Aprobar solicitud"
              v-can="'product_withdrawal_requests.update'"
              class="iq-bg-success mt-0"
              variant="none"
              v-confirm="{
                ok: (dialog) => egresoProducts(data.item, 2),
                message: '¿Esta Seguro de Dar De Baja a Este Producto?',
              }"
              ><i class="ri-check-line ml-1"></i>
            </b-button>
            <b-button
              v-b-tooltip.hover
              title="Rechazar Solicitud"
              v-can="'product_withdrawal_requests.update'"
              class="iq-bg-danger mt-0 ml-2"
              variant="none"
              v-confirm="{
                ok: (dialog) => egresoProducts(data.item, 0),
                message: '¿Desea Cancelar Esta Solicitud?',
              }"
              ><i class="ri-close-circle-line ml-1"></i>
            </b-button>
          </template>
        </b-table>
        <b-pagination
          v-if="egress.length > perPage"
          class="mt-3"
          v-model="currentPage"
          :total-rows="egress.length"
          :per-page="perPage"
          first-number
          pills
          size="sm"
          align="center"
        ></b-pagination>
      </template>
      <b-modal id="details" size="lg" hide-footer>
        <template #modal-title> Detalles de Solicitudes </template>
        <div class="d-block">
          <b-row class="text-dark">
            <b-col md="5"><strong class="ml-5">CÓDIGO: </strong></b-col>
            <b-col md="5" class="ml-auto mr-5"
              >#{{ details.code_product_responsable }}</b-col
            >

            <!-- <b-col md="5"><strong class="ml-5">TIPO: </strong></b-col>
            <b-col md="5" class="ml-auto mr-5">
              <b-badge pill class="bg-info">
                <h5 class="text-white">Movimiento</h5>
              </b-badge></b-col
            > -->

            <b-col md="5"><strong class="ml-5">PRODUCTO: </strong></b-col>
            <b-col md="5" class="ml-auto mr-5">{{ details.product }}</b-col>

            <b-col md="5"><strong class="ml-5">SOLICITANTE: </strong></b-col>
            <b-col md="5" class="ml-auto mr-5">{{
              details.approved_by !== null ? details.approved_by : "En espera"
            }}</b-col>

            <b-col md="5"
              ><strong class="ml-5">FECHA DE APROBACIÓN: </strong></b-col
            >
            <b-col md="5" class="ml-auto mr-5">{{
              details.approved_at !== null ? details.approved_at : "En espera"
            }}</b-col>

            <b-col md="5"><strong class="ml-5">ESTADO: </strong></b-col>
            <b-col md="5" class="ml-auto mr-5">
              <b-badge pill class="bg-info">
                <h5 class="text-white">
                  {{ details.status_text }}
                </h5>
              </b-badge></b-col
            >

            <b-col md="5"><strong class="ml-5">RESPONSABLE: </strong></b-col>
            <b-col md="5" class="ml-auto mr-5" v-if="details.user">{{
              details.user.name
            }}</b-col>

            <!-- <b-col md="5" v-if="details.file !== 0"
            ><strong class="ml-5">DOCUMENTO: </strong></b-col
          > -->
            <!-- <b-col md="5" class="ml-auto mr-5 mb-0" v-if="details.file !== 0">
            <b-link :href="details.file" target="_blank">Ver Documento</b-link>
          </b-col> -->
            <span class="ml-5 mr-5 p-3 pt-0"
              ><strong>DESCRIPCIÓN: </strong>{{ details.description }}</span
            >
          </b-row>
        </div>
      </b-modal>
      <Historial ref="historial" :pe="rows" :columns="columns" :state="state" />
    </iq-card>
  </div>
</template>
<script>
import { core } from "@/config/pluginInit";
import { mapActions, mapGetters } from "vuex";
import Historial from "@/components/core/modals/Historial.vue";
export default {
  components: {
    Historial,
  },
  props: {
    state: { default: false },
  },
  async mounted() {
     this.isBusy = true
    await this.movementsData();
    await this.listWithdrawal();
     this.isBusy = false

    core.index();
  },
  data() {
    return {
      columns: [
        {
          label: "CÓDIGO",
          key: "code_product_responsable",
          class: "text-center",
        },
        { label: "PRODUCTO", key: "product", class: "text-center" },
        { label: "SOLICITANTE", key: "user.name", class: "text-center" },
        { label: "TIPO", key: "type", class: "text-center" },
        { label: "ESTADO", key: "status_text", class: "text-center" },
        { label: "ACCIONES", key: "action", class: "text-center" },
      ],
      details: "",
      perPage: 5,
      currentPage: 1,
      isBusy: false,
      isProcess: {status: false, id: null}
    };
  },
  methods: {
    ...mapActions({
      movementsData: "products/movementStockList",
      updateMovement: "products/updateMovement",
      listWithdrawal: "withdrawal/listWithdrawal",
      updateWithdrawal: "withdrawal/updateWithdrawal",
    }),
    show(item) {
      this.details = item;
      this.$bvModal.show("details");
    },

    async egresoProducts(data, status) {
      try {
        await this.updateWithdrawal({ id: data.id, status: status });
        this.$store.commit("products/SET_CHANGE_STATUS_ANSWERABLE", { code: data.code_product_responsable, status: status });
        // this.$emit("refresh");
        core.showSnackbar(
          "success",
          status === 0 ? "Solicitud Rechazada" : "Solicitud Aprobada"
        );
      } catch (error) {
        const errors = error.response.data.errors;
        for (const key in errors) {
          core.showSnackbar("error", errors[key][0]);
          return false;
        }
      }
    },

    async movement(id, status) {
      try {
        await this.updateMovement({ id: id, status: status });

        // await this.$emit("refresh");
        core.showSnackbar(
          "success",
          status === 0 ? "Solicitud Rechazada" : "Solicitud Aprobada"
        );
      } catch (error) {
        const errors = error.response.data.errors;
        for (const key in errors) {
          core.showSnackbar("error", errors[key][0]);
          return false$;
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      movemenPending: "products/getMovemenPending",
      withdrawalPending: "withdrawal/getwithdrawalPending",
    }),
    egress(){
      return this.withdrawalPending
    },
    rows(){
      return this.movemenPending;
    }
  },
};
</script>

